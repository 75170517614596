$(document).ready(function() {
	var optimizelyScriptUrl = $('#optimizelyTrackingUrl').attr('data-value');
	var existingPeeriusScripts = document.querySelectorAll('script[src*="peerius.com/tracker"]');
	if (existingPeeriusScripts.length > 0) {
	    existingPeeriusScripts.forEach(function (scriptElement) {
	        scriptElement.parentNode.removeChild(scriptElement);
	    });
	}
	var optimizelyScript = document.createElement('script');
	optimizelyScript.src = optimizelyScriptUrl;
	document.head.appendChild(optimizelyScript);
});